import React from "react"
import { Link, withPrefix } from "gatsby"
import Layout from "@layout/layout"
import { pl, en } from "@content/pages/media.yaml"
import HeaderSubpage from "@layout/header-subpage"
import SEO from "@layout/seo"

export default ({isEn}) => {
  const translation = isEn ? en : pl

  return (
    <Layout isEn={isEn}>
      <SEO
        isEn={isEn}
        title={translation.metaTitle}
        description={translation.metaDescription}
      />
      <HeaderSubpage isEn={isEn}/>
      <section className="section wrapper">
        <div
          className="padding sasaboxbg text-center b-lazy  b-loaded"
          style={{
            backgroundImage: `url(${withPrefix("images/media/hero.png")})`,
          }}
        >
          <div className="row collapse">
            <div className="large-12 columns text-center">
              <h1 style={{ color: "#ffffff" }} />
            </div>
          </div>
        </div>
        <section
          className="news-text padding wow fadeInUp"
          data-wow-duration="1.5s"
          style={{
            visibility: "hidden",
          }}
        >
          <div className="row">
            <div className="large-8 large-centered medium-12 small-12 columns">
              <p className="p1">
                <strong>
                  Szukasz ciekawego tematu do programu lub reportażu? Dobrze
                  trafiłeś.
                </strong>
              </p>
              <p className="p1">
                <span style={{ fontWeight: 400 }}>
                  Fundacja SASA to przede wszystkim ludzie.
                </span>
                Naszą wiedzą i zaangażowaniem chcemy ulżyć w cierpieniu
                pacjentom na całym świecie i przywrócić im zdrowie. Pracując dla
                lokalnych społeczności w krajach takich jak Tanzania, Ukraina i
                Polska, zbieramy unikalne doświadczenia, którymi chętnie się z
                Tobą podzielimy.{" "}
                <span style={{ fontWeight: 400 }}>
                  Obserwujemy, zbieramy materiały, słuchamy… wszystko po to by
                  mogła zostać opowiedziana historia o problemie, który
                  zdecydowaliśmy się rozwiązać.
                </span>
              </p>
              <p>
                <strong>Do tej pory pisali o nas:</strong>
                <br />
                <strong>2017</strong>
              </p>
              <p>
                Fundacja SASA w Teleekspresie (8:29) &gt;&gt;&nbsp;
                <a href="https://teleexpress.tvp.pl/30974602/16062017-1700">
                  zobacz
                </a>
                <br />
                “Polscy studenci medycyny wesprą szpital w Tanzanii”, Polskie
                Radio &gt;&gt;&nbsp;
                <a href="http://www.polskieradio.pl/130/5925/Artykul/1778184,Polscy-studenci-medycyny-wespra-szpital-w-Tanzanii">
                  przeczytaj
                </a>
                <br />
                “Młodzi lekarze – wolontariusze wyjadą wspomóc tanzańską Izbę
                Przyjęć!”, ESKA Lublin &gt;&gt;{" "}
                <a href="http://lublin.eska.pl/newsy/mlodzi-lekarze-wolontariusze-z-krasnika-wyjada-wspomoc-tanzanska-izbe-przyjec/466490">
                  przeczytaj
                </a>
                <br />
                “Nasi studenci pomagają szpitalowi w Tanzanii. Przed nimi
                kolejna misja”, Rynek Zdrowia &gt;&gt;{" "}
                <a href="http://www.rynekzdrowia.pl/Po-godzinach/Nasi-studenci-pomagaja-szpitalowi-w-Tanzanii-Przed-nimi-kolejna-misja,173773,10.html">
                  przeczytaj
                </a>
                <br />
                “Wyjątkowi ludzie, wyjątkowa rozmowa… Wolontariusze Fundacji
                SASA opowiadają o pomocy, z którą jadą do Tanzanii”,
                Businesswoman&amp;Life &gt;&gt;{" "}
                <a href="http://businesswomanlife.pl/wyjatkowi-ludzie-wyjatkowa-rozmowa-wolontariusze-fundacji-sasa-opowiadaja-o-pomocy-z-ktora-jada-do-tanzanii/">
                  przeczytaj
                </a>
                <br />
                “Meds Students for Africa!”, MeddyBear &gt;&gt;{" "}
                <a href="https://www.meddybear.net/meds-students-africa/">
                  przeczytaj
                </a>
                <br />
                “Wolontariusze SASA będą leczyć w tanzańskim szpitalu”, Rynek
                Aptek&nbsp;&gt;&gt;{" "}
                <a href="http://www.rynekaptek.pl/marketing-i-zarzadzanie/wolontariusze-sasa-beda-leczyc-w-tanzanskim-szpitalu,20655_1.html">
                  przeczytaj
                </a>
                <br />
                “Chojniczanka z misją w Tanzanii. Wraz z lekarzami i studentami
                medycyny pomaga chorym”, Weekendfm.pl &gt;&gt;{" "}
                <a href="http://weekendfm.pl/?n=65120&chojnice_-chojniczanka_z_misja_w_tanzanii_wraz_z_lekarzami_i_studentami_medycyny_pomaga_chorym">
                  przeczytaj
                </a>
                <br />
                “Polscy wolontariusze pomagają wyposażyć tanzańską Izbę
                Przyjęć”, Portale Medyczne &gt;&gt;{" "}
                <a href="http://www.portalemedyczne.pl/8585/5608/polscy-wolontariusze-pomagaja-wyposazyc-tanzanska/">
                  przeczytaj
                </a>
                <br />
                “Na koniec świata i jeszcze bliżej”, Drugie Śniadanie Mistrzów
                &gt;&gt;{" "}
                <a href="http://drugiesniadaniemistrzow.blogspot.com/2017/05/biala-niedziela.html">
                  przeczytaj
                </a>
                <br />
                “Ta akcja przypomniała mi, dlaczego wybrałam studia medyczne”,
                MP.pl &gt;&gt;{" "}
                <a href="http://www.mp.pl/kurier/164564,ta-akcja-przypomniala-mi-dlaczego-wybralam-studia-medyczne">
                  przeczytaj
                </a>
              </p>
              <p>
                <strong>2016</strong>
              </p>
              <p>
                “Polska studentka medycyny na Ukrainie: “Świńska grypa nie jest
                największym zmartwieniem.” Jest nim wojna”, National Geographic
                &gt;&gt;{" "}
                <a href="http://www.national-geographic.pl/aktualnosci/swinska-grypa-na-ukrainie-to-nie-problem-jest-inny-znacznie-gorszy-mowi-sanitariuszka">
                  przeczytaj
                </a>
                <br />
                “Białe Niedziele”, Jedwabno.pl &gt;&gt;{" "}
                <a href="https://www.jedwabno.pl/12688/biale-niedziele-22-05-2016-rok">
                  przeczytaj
                </a>
              </p>
              <p>
                <strong>
                  2015
                  <br />
                </strong>
                <br />
                „Przyszli lekarze na pomoc światu”, Wprost &gt;&gt;{" "}
                <a href="https://www.wprost.pl/516082/Przyszli-lekarze-na-pomoc-swiatu">
                  przeczytaj
                </a>
                <br />
                „Hipokrates w Tanzanii: przy amputacji nogi, dwóch chirurgów
                musiało podzielić się jedną parą rękawiczek”, Wysokie Obcasy
                &gt;&gt;{" "}
                <a href="http://www.wysokieobcasy.pl/wysokie-obcasy/1,80530,19319363,hipokrates-w-tanzanii-przy-amputacji-nogi-dwoch-chirurgow.html">
                  przeczytaj
                </a>
                <br />
                „Fundacja SASA – Pomoc medyczna dla Tanzanii wprost z Poznania”,
                Freshmag &gt;&gt;{" "}
                <a href="http://freshmag.pl/magazyn/lifestyle/fundacja-sasa-%E2%80%93-pomoc-medyczna-dla-tanzanii-wprost-z-poznania">
                  przeczytaj
                </a>
                <br />
                „Fundacja SASA i pomagam.pl”, Dental Radio &gt;&gt;{" "}
                <a href="http://www.dentalradio.pl/news/show/fundacja_sasa_i_pomagampl">
                  przeczytaj
                </a>
                <br />
                “Martyna Kaczmarek rozmawia z Gabrielą Stępińską i Olgą
                Korycińską”, Medium#Publiczne &gt;&gt;{" "}
                <a href="http://mediumpubliczne.pl/2015/11/martyna-kaczmarek-rozmawia-z-gabriela-stepinska-i-olga-korycinska/">
                  przeczytaj
                </a>
                <br />
                “Przyszli lekarze na pomoc światu”, Studenci Medycyny &gt;&gt;{" "}
                <a href="http://www.studencimedycyny.pl/a/Przyszli_lekarze_na_pomoc_swiatu-1993">
                  przeczytaj
                </a>
                <br />
                “Twój grosz poleci do Tanzanii!”, eKopernik &gt;&gt;{" "}
                <a href="http://ekopernik.com.pl/?p=1356">przeczytaj</a>
                <br />
                “Fundacja SASA – zbiórka pieniędzy na pomoc medyczną dla
                Tanzanii”, Będąc Młodym Lekarzem &gt;&gt;{" "}
                <a href="http://www.bedacmlodymlekarzem.pl/2015/08/fundacja-sasa-zbiorka-pieniedzy-na-pomoc-medyczna-dla-tanzanii/">
                  przeczytaj
                </a>
              </p>
            </div>
          </div>
        </section>
      </section>
    </Layout>
  )
}
